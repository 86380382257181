<template>
  <CLoading :loading="loading" />
  <q-table
    bordered
    flat
    dense
    title="Treats"
    ref="welcomeList"
    v-if="popups"
    :rows="popups"
    row-key="title"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th rowspan="2" key="action" class="text-center" style="width: 50px">{{
          $t('label.audience_user.is_active')
        }}</q-th>
        <q-th rowspan="2" key="action" class="text-center" style="width: 100px"></q-th>
        <q-th rowspan="2" class="text-center" style="width: 64px"></q-th>

        <q-th :props="props" rowspan="2" key="popup_name">
          <span class="text-bold">{{ $t('label.popup.popup_name') }}</span>
        </q-th>
        <q-th :props="props" rowspan="2" key="total_display">
          <span class="text-bold">{{ $t('label.total_display') }}</span>
        </q-th>
        <q-th :props="props" rowspan="2" key="click_rate">
          <span class="text-bold">{{ $t('label.click_rate') }}</span>
        </q-th>
        <q-th :props="props" rowspan="2" key="total_click">
          <span class="text-bold">{{ $t('label.total_click') }}</span>
        </q-th>
        <!-- <q-th :props="props" rowspan="2" key="success_rate">
          <span class="text-bold">CVR</span>
        </q-th>
        <q-th :props="props" rowspan="2" key="success_count">
          <span class="text-bold">CV</span>
        </q-th> -->
        <q-th :props="props" rowspan="2" key="updated_at">
          <span class="text-bold">{{ $t('label.updated_at') }}</span>
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" :index="props.rowKey">
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_active"
            val="props.row.is_active"
            @click="handleToggleActive(props.row)"
          />
        </q-td>
        <q-td key="action" :props="props.index">
          <q-btn size="sm" round outline icon="edit" @click="onEdit(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline color="grey" icon="content_copy" @click="onCopy(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline color="red" icon="delete" @click="onDelete(props.row)" class="q-mr-sm" />
        </q-td>
        <q-td>
          <q-avatar rounded size="64px" v-if="props.row.images">
            <img :src="props.row.images[0].pc.img_thumb" />
          </q-avatar>
        </q-td>
        <q-td key="popup_name" :props="props">
          {{ props.row.popup_name }}
        </q-td>
        <q-td key="total_display" :props="props">
          {{ numberWithCommas(props.row.total_display) }}
        </q-td>
        <q-td key="click_rate" :props="props"> {{ roundNumber(props.row.click_rate) }}% </q-td>
        <q-td key="total_click" :props="props">
          {{ numberWithCommas(props.row.total_click) }}
        </q-td>
        <!-- <q-td key="success_rate" :props="props">
          {{ props.row.success_rate }}%
        </q-td>
        <q-td key="success_count" :props="props">
          {{ props.row.success_count }}
        </q-td> -->
        <q-td key="updated_at" :props="props">
          {{ toLocalTime(props.row.updated_at) || '-' }}
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top>
      <h2 class="text-h6 text-black">{{ $t('label.popup.popup_list') }}</h2>
      <q-select
        outlined
        dense
        option-value="_id"
        option-label="campaign_name"
        v-model="selectedCampaignId"
        :options="campaigns"
        emit-value
        map-options
        class="q-ma-md"
        style="width: 240px"
        :label="$t('label.popup.campaign')"
      />
      <q-space />
      <q-btn no-caps color="primary" @click="onAdd(0)" :label="$t('add_new')" />
    </template>
  </q-table>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IPopup } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_CAMPAIGN, ACTION_POPUP } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import { constant } from '@/utils/constants'
import { localTime } from '@/utils/datetime'
import { Formater } from '@/utils/formater'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: { CLoading },
  directives: { maska },
  emits: [],
})
export default class PopupList extends mixins(BaseFormMixin) {
  loading = true
  selectedCampaignId = ''

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get campaignId() {
    return this.$route.params.campaign_id || ''
  }

  get campaigns() {
    return cloneDeep(this.$store.getters.campaigns) || []
  }

  get popups() {
    return cloneDeep(this.$store.getters.popups) || []
  }

  @Watch('campaignId', { immediate: true })
  async loadData() {
    if (!this.selectedAppId) {
      return
    }

    await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      is_traffic_source: false,
    })

    await this.$store.dispatch(ACTION_POPUP.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      campaign_id: this.campaignId,
    })

    this.selectedCampaignId = this.campaignId
    this.loading = false
  }

  async updateIsActive(item: IPopup) {
    const isSuccess = await this.$store.dispatch(ACTION_POPUP.IS_ACTIVE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: IPopup) {
    const item = this.popups.find((item) => item._id !== record._id && item.is_active === true)
    if (!item) {
      this.updateIsActive(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          item.is_active = false
          this.updateIsActive(item)
        })

      record.is_active = false
    }
  }

  onAdd() {
    this.goto('popup_setting', {
      app_id: this.selectedAppId,
      campaign_id: this.campaignId,
      action_type: constant.ACTION_TYPE.ADD,
    })
  }

  onEdit(data: IPopup) {
    this.goto('popup_setting', {
      app_id: this.selectedAppId,
      campaign_id: this.campaignId,
      popup_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  onCopy(data: IPopup) {
    this.goto('popup_setting', {
      app_id: this.selectedAppId,
      campaign_id: this.campaignId,
      popup_id: data._id,
      action_type: constant.ACTION_TYPE.COPY,
    })
  }

  async onDelete(data: IPopup) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_POPUP.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  @Watch('selectedCampaignId')
  handleChangeCampaign() {
    this.goto('popups', {
      app_id: this.selectedAppId,
      campaign_id: this.selectedCampaignId,
    })
  }

  toLocalTime(datetime: Date) {
    return localTime(datetime)
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }
}
</script>
<style lang="sass" scoped></style>
